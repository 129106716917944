import '../styles/Chart.css'
import React, { useState, useEffect } from 'react';
function ChartComp() {
  return (
    <div className="Main">
      <div className='Header'>
      <p className='main-paragraph'> History is being made in the Crypto space with the Top G's meta; <span className='coin-name'>$RNT</span>- Real Nigger Tate,
      <span className='coin-name'>$DADDY</span>- DaddyTate, <span className='coin-name'>$TOPG</span>- TOP G, making us all filthy rich. 
We need a log book to keep all that historical events secured for the future degens to remember the matrix was broken by The Alpha Male,
 Andrew Tate. The Top G's logbook, <span className='coin-name'>$BOAT</span>- Book Of Andrew Tate.
        </p>
            
      </div>
      <img src={"tate.png"} alt="error loading" className='main-image'/>
    </div>
  );
}

export default ChartComp;
